import { template as template_aadfa6211c4d4012972d3f955df3b329 } from "@ember/template-compiler";
const FKControlMenuContainer = template_aadfa6211c4d4012972d3f955df3b329(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
