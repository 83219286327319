import { template as template_87e66fcae0dc4a5d9d7ac87bf45f602a } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_87e66fcae0dc4a5d9d7ac87bf45f602a(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
